<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>

    export default {
        name: 'app',

    }
</script>

<style lang="less">
    * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box
    }
    @font-face {
        font-family: 'webfont';
        font-display: swap;
        src: url('//at.alicdn.com/t/webfont_dktvyqc49bs.eot'); /* IE9*/
        src: url('//at.alicdn.com/t/webfont_dktvyqc49bs.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('//at.alicdn.com/t/webfont_dktvyqc49bs.woff2') format('woff2'),
        url('//at.alicdn.com/t/webfont_dktvyqc49bs.woff') format('woff'), /* chrome、firefox */
        url('//at.alicdn.com/t/webfont_dktvyqc49bs.ttf') format('truetype'), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
        url('//at.alicdn.com/t/webfont_dktvyqc49bs.svg#思源黑体-极细') format('svg'); /* iOS 4.1- */
    }
    body {
        margin: 0;
        font-family: "webfont" !important;
    }

    #app {
        font-family: "webfont" !important;
    }

</style>
