//定义api地址
let apiUrl = '';
let websocketUrl = '';
if (process.env.NODE_ENV == 'development') {
    apiUrl = 'http://flapi.zuzulu.com/';
    // apiUrl = 'https://mes.frdr.cn:8443/';
    websocketUrl = 'ws://121.199.74.145:2348';
} else {
    apiUrl = 'https://mes.frdr.cn:8443/';
    websocketUrl = 'wss://data.freyman.cn/websocket';
}
export default {
    apiUrl,websocketUrl
}