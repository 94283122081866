import axios from 'axios';
import router from "../router";
import _config from "../config";

const http = axios.create({
    baseURL: _config.apiUrl,
    timeout: 40000,
    withCredentials: false
});


//添加请求拦截器
http.interceptors.request.use(config => {
    config.headers = Object.assign({
        'Content-Type': 'application/json',
    }, config.headers);

    var token = localStorage.getItem('token') || '';
    if (token != '') {
        config.headers['token'] = token;
    }
    console.log(config);
    return config;
}, err => {
    return Promise.resolve(err);
});

//响应拦截器
http.interceptors.response.use(
    response => {
        let res = response.data;
        if (res.isSuccess) {
            if (res.message) {
                //alert(res.message);
            }
            return response.data;
        } else if (res.errorCode === 4004 || res.errorCode === 4005) {
            //alert(res.message);
            router.push("/login");
            return Promise.reject(res);
        } else {
            //alert(res.message);
            return Promise.reject(res);
        }
    },
    error => {
        console.log(error);
        if (error.response && error.response.data && error.response.data.message) {
            var jsonObj = JSON.parse(error.response.data.message);
            //alert(jsonObj.message);
        } else {
            //alert(error.message);
        }
        return Promise.reject(error);
    }
);

export default http;