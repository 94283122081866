import Vue from 'vue'
import _config from './config'
// import 'amfe-flexible'
Vue.prototype._config = _config;
import App from './App.vue'
// import DataV from '@jiaminghi/data-view'
import router from './router'
import http from './utils/http';
import rem from './utils/rem'



import scroll from 'vue-seamless-scroll'
Vue.use(scroll)

// Vue.use(DataV)
Vue.config.productionTip = false
Vue.prototype.axios = http;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
